



























import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import url from '@/lib/url'
import ListBase from '@/lib/listbase';
import User from '@/store/entities/admin/user';
import FormBase from '@/components/helper/form-base.vue';
import VWidget from '@/components/VWidget.vue';


@Component({
    components: { FormBase, VWidget }
})
export default class UserProfile extends ListBase {
    user: User = new User();
    formBase: boolean = false;
    image: any = null;
    modalSetting: any = {
        entity: "user",
        title: 'Usuarios',
        width: "700px"
    };

    items = [ 
        this.gItem({ type: 'text', prop: 'first_name', label: 'Nombre', required: true, size: 6 }),
        this.gItem({ type: 'text', prop: 'last_name', label: 'Apellidos', size: 6 }),
        this.gItem({ type: 'text', prop: 'username', label: 'Usuario', required: true, size: 6 }),
        this.gItem({ type: 'password', prop: 'password', label: 'Password', size: 6, hint:'Minimo 8 caracteres', persistentHint: true }),
        this.gItem({ type: 'email', prop: 'email', label: 'Email', size: 6 }),
        this.gItem({ type: 'spacer', size: 6 }),
        this.gItem({ type: 'divider', subheader: 'Asociaciones' }),
        this.gItem({ type: 'object', prop: 'default_tab_id', label: 'Menu Por Defecto', entity: 'tab', size: 6 }),
        this.gItem({ type: 'spacer', size: 6 }),
    ]

    get imageUrl() {
        return (this.image != null ? this.image :
            (this.user.avatar_url != null ? this.user.avatar_url : null));
    }

    get loading() {
        return this.$store.state.user.loading;
    }

    initForm() {
        this.setItem(this.items, 'username', 'disabled', (this.user.id == 1));
    }

    openImage() {
        (this.$refs.fileInput as any).click();
    }

    onFileChange() {
        let ipt = (this.$refs.fileInput as any);
        if (ipt.files == undefined) return;
        if (ipt.files.length > 0) {
            if (!ipt.files.length)
                return;
            let file = ipt.files[0];

            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                vm.image = (e as any).target.result;
            };
            reader.readAsDataURL(file);
        }
    }

    async save() {
        if (this.$refs.entityForm != undefined && (this.$refs.entityForm as any).validate()) {
            let model = Util.extend(true, {}, this.user); 
            if (this.image != null)
                model.avatarData = this.image;

            let mewModel = await this.$store.dispatch({
                type: 'user/updateProfile',
                data: model
            });
            
            this.user.avatar_url = mewModel.avatar_url;

            Util.abp.notify.success(this, 'Tu Perfil ha sido actualizado');
            this.$store.commit('session/setUser', this.user);
        }
    }


    mounted() {
        this.user = Util.extend(true, {}, this.$store.state.session.user);
        this.initForm();
    }
}